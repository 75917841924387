<template>
  <div>
    <div class="level is-mobile">
      <div class="level-left">
        <div class="level-item">
          <span class="has-text-grey has-text-weight-bold">
            Settings
          </span>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          <b-field>
            <b-switch
              type="is-info"
              v-model="settings"
            />
          </b-field>
        </div>
      </div>
    </div>

    <div class="level is-mobile">
      <div class="level-left">
        <div class="level-item">
          <span class="has-text-grey has-text-weight-bold">
            Connectors
          </span>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          <b-field>
            <b-switch
              type="is-info"
              v-model="connectors"
            />
          </b-field>
        </div>
      </div>
    </div>

    <div class="level is-mobile">
      <div class="level-left">
        <div class="level-item">
          <span class="has-text-grey has-text-weight-bold">
            Fields
          </span>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          <b-field>
            <b-switch
              type="is-info"
              v-model="fields"
            />
          </b-field>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

  name: 'PermissionsAdminForm',

  computed: {
    settings: {
      get() {
        return this.$store.state.Permissions.permissions.admin.settings;
      },

      set(value) {
        return this.$store.commit('Permissions/SET_ADMIN_SETTINGS_PERMISSIONS', value);
      },
    },

    connectors: {
      get() {
        return this.$store.state.Permissions.permissions.admin.connectors;
      },

      set(value) {
        return this.$store.commit('Permissions/SET_ADMIN_CONNECTORS_PERMISSIONS', value);
      },
    },

    fields: {
      get() {
        return this.$store.state.Permissions.permissions.admin.fields;
      },

      set(value) {
        return this.$store.commit('Permissions/SET_ADMIN_FIELDS_PERMISSIONS', value);
      },
    },
  },
};
</script>

<style lang="css" scoped>
</style>
