<template>
  <div>
    <div class="content">
      <span class="subtitle has-text-weight-bold has-text-info">
        Teams
      </span>
    </div>

    <div class="level is-mobile">
      <div class="level-left">
        <div class="level-item">
          <span class="has-text-grey has-text-weight-bold">
            View Teams
          </span>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          <b-field>
            <b-switch
              type="is-info"
              v-model="view"
            />
          </b-field>
        </div>
      </div>
    </div>

    <div class="level is-mobile">
      <div class="level-left">
        <div class="level-item">
          <span class="has-text-grey has-text-weight-bold">
            Create Teams
          </span>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          <b-field>
            <b-switch
              type="is-info"
              v-model="create"
            />
          </b-field>
        </div>
      </div>
    </div>

    <div class="level is-mobile">
      <div class="level-left">
        <div class="level-item">
          <span class="has-text-grey has-text-weight-bold">
            Edit Teams
          </span>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          <b-field>
            <b-switch
              type="is-info"
              v-model="edit"
            />
          </b-field>
        </div>
      </div>
    </div>

    <div class="level is-mobile">
      <div class="level-left">
        <div class="level-item">
          <span class="has-text-grey has-text-weight-bold">
            Delete Teams
          </span>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          <b-field>
            <b-switch
              type="is-info"
              v-model="remove"
            />
          </b-field>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

  name: 'PermissionsTeamsform',

  computed: {
    view: {
      get() {
        return this.$store.state.Permissions.permissions.admin.teams.view;
      },

      set(value) {
        return this.$store.commit('Permissions/SET_ADMIN_TEAMS_VIEW', value);
      },
    },

    create: {
      get() {
        return this.$store.state.Permissions.permissions.admin.teams.create;
      },

      set(value) {
        return this.$store.commit('Permissions/SET_ADMIN_TEAMS_CREATE', value);
      },
    },

    edit: {
      get() {
        return this.$store.state.Permissions.permissions.admin.teams.edit;
      },

      set(value) {
        return this.$store.commit('Permissions/SET_ADMIN_TEAMS_EDIT', value);
      },
    },

    remove: {
      get() {
        return this.$store.state.Permissions.permissions.admin.teams.delete;
      },

      set(value) {
        return this.$store.commit('Permissions/SET_ADMIN_TEAMS_DELETE', value);
      },
    },
  },

};
</script>

<style lang="css" scoped>
</style>
