<template>
  <dashboard-layout>
    <template #content>
      <b-loading
        :is-full-page="true"
        v-model="isLoading"
        :can-cancel="true"
      />

      <div class="level dashboard-navbar">
        <div class="level-left">
          <div class="level-item">
            <div class="content">
              <h1 class="title has-text-primary">
                <b-icon
                  icon="key"
                  type="is-primary"
                />
                Permissions for
              </h1>
            </div>
          </div>

          <div class="level-item">
            <b-dropdown aria-role="list">
              <button
                class="button is-white is-capitalized"
                slot="trigger"
                slot-scope="{ active }"
              >
                <span>
                  {{
                    Object.keys(selectedRole).includes('name') ?
                      selectedRole.name.replace(/_/g, ' ').substring(5).toLowerCase()
                      : 'Please select a role'
                  }}
                </span>
                <b-icon
                  :icon="active ? 'chevron-up' : 'chevron-down'"
                  size="is-small"
                />
              </button>

              <b-dropdown-item
                v-for="role in roles"
                :key="role.id"
                aria-role="listitem"
                class="is-capitalized"
                @click="fetchPermissions(role)"
              >
                {{ role.name | role }}
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </div>

      <section class="section">
        <div class="container">
          <div
            class="columns"
            v-if="selectedRole.name"
          >
            <div class="column is-one-third">
              <permissions-contacts-form class="is-spaced-top" />
              <permissions-opportunities-form class="is-spaced-top" />
              <permissions-sales-form class="is-spaced-top" />
              <permissions-accounts-form class="is-spaced-top" />
              <permissions-activities-form class="is-spaced-top" />
              <permissions-lookups-form class="is-spaced-top" />
              <permissions-project-folders-form class="is-spaced-top" />
            </div>

            <div class="column">
              <permissions-leads-form class="is-spaced-top" />
              <permissions-stages-form class="is-spaced-top" />
              <permissions-reports-form class="is-spaced-top" />
              <permissions-goals-form class="is-spaced-top" />
              <permissions-events-form class="is-spaced-top" />
              <permissions-documents-form class="is-spaced-top" />
              <permissions-order-quote-financials-form class="is-spaced-top" />
            </div>

            <div class="column">
              <div class="box has-background-light">
                <div class="content">
                  <span
                    class="heading has-divider is-size-5 has-text-weight-bold has-text-primary"
                  >
                    Admin
                  </span>
                </div>
                <permissions-admin-form />
                <permissions-roles-form class="is-spaced-top" />
                <permissions-users-form class="is-spaced-top" />
                <permissions-teams-form class="is-spaced-top" />
                <permissions-divisions-form class="is-spaced-top" />
                <permissions-custom-fields-form class="is-spaced-top" />
                <permissions-organizations-form class="is-spaced-top" />
              </div>
            </div>
          </div>

          <div
            class="columns is-vcentered is-centered"
            v-else
          >
            <div class="column is-half">
              <div class="level">
                <div class="level-item has-text-centered">
                  <div>
                    <b-image
                      :src="require('@/assets/images/select.svg')"
                      alt="The Buefy Logo"
                      ratio="6by4"
                    />

                    <b-dropdown aria-role="list">
                      <button
                        class="button is-primary"
                        slot="trigger"
                        slot-scope="{ active }"
                      >
                        <span>
                          {{
                            Object.keys(selectedRole).includes('name') ?
                              selectedRole.name.replace(/_/g, ' ').substring(5) :
                              'Please select a role'
                          }}
                        </span>
                        <b-icon
                          :icon="active ? 'chevron-up' : 'chevron-down'"
                          size="is-small"
                        />
                      </button>

                      <b-dropdown-item
                        v-for="role in roles"
                        :key="role.id"
                        aria-role="listitem"
                        class="is-capitalized"
                        @click="fetchPermissions(role)"
                      >
                        {{ role.name | role }}
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </template>
  </dashboard-layout>
</template>

<script>
import { mapGetters } from 'vuex';

import { DashboardLayout } from '@/layouts';
import {
  PermissionsAccountsForm,
  PermissionsContactsForm,
  PermissionsGoalsForm,
  PermissionsLeadsForm,
  PermissionsOpportunitiesForm,
  PermissionsReportsForm,
  PermissionsSalesForm,
  PermissionsStagesForm,
  PermissionsActivitiesForm,
  PermissionsEventsForm,
  PermissionsLookupsForm,
  PermissionsDocumentsForm,
  PermissionsProjectFoldersForm,
  PermissionsOrderQuoteFinancialsForm,

  PermissionsAdminForm,
  PermissionsCustomFieldsForm,
  PermissionsRolesForm,
  PermissionsTeamsForm,
  PermissionsDivisionsForm,
  PermissionsUsersForm,
  PermissionsOrganizationsForm,
} from '@/components/Permissions';

export default {

  name: 'Permissions',

  components: {
    DashboardLayout,

    PermissionsAccountsForm,
    PermissionsContactsForm,
    PermissionsGoalsForm,
    PermissionsLeadsForm,
    PermissionsOpportunitiesForm,
    PermissionsReportsForm,
    PermissionsSalesForm,
    PermissionsStagesForm,
    PermissionsActivitiesForm,
    PermissionsEventsForm,
    PermissionsLookupsForm,
    PermissionsDocumentsForm,
    PermissionsProjectFoldersForm,
    PermissionsOrderQuoteFinancialsForm,

    PermissionsAdminForm,
    PermissionsCustomFieldsForm,
    PermissionsRolesForm,
    PermissionsTeamsForm,
    PermissionsDivisionsForm,
    PermissionsUsersForm,
    PermissionsOrganizationsForm,
  },

  computed: {
    ...mapGetters({
      selectedRole: 'Roles/getSelectedRole',
      roles: 'Roles/getRoles',
      permissions: 'Permissions/getPermissions',
      postPermissions: 'Permissions/getPostPermissions',
      isLoading: 'Permissions/getLoading',
    }),
  },

  async created() {
    await this.$store.dispatch('Roles/fetchRoles');
  },

  async beforeDestroy() {
    await this.$store.dispatch('Permissions/updateRolePermissions', {
      id: this.selectedRole.id,
      name: this.selectedRole.name,
      description: this.selectedRole.description,
      permissions: this.postPermissions,
    });
  },

  filters: {
    role(value) {
      return value.replace(/_/g, ' ').substring(5).toLowerCase();
    },
  },

  methods: {
    async fetchPermissions(role) {
      try {
        if (this.postPermissions.length !== 0) {
          await this.$store.dispatch('Permissions/updateRolePermissions', {
            id: this.selectedRole.id,
            name: this.selectedRole.name,
            description: this.selectedRole.description,
            permissions: this.postPermissions,
          });
          this.$buefy.notification.open({
            message: `Successfully updated ${this.selectedRole.name.replace(/_/g, ' ')} permissions`,
            type: 'is-success',
          });
          this.$store.commit('Permissions/CLEAR_POST_PERMISSIONS', []);
        }
        this.$store.commit('Roles/SET_SELECTED_ROLE', role);
        await this.$store.dispatch('Permissions/fetchRolePermissions', role.name);
        return this.$buefy.notification.open({
          message: `Successfully retrieved ${role.name.replace(/_/g, ' ').toLowerCase()} permissions`,
          type: 'is-success',
        });
      } catch (error) {
        return this.$buefy.notification.open({
          message: error.message,
          type: 'is-warning',
        });
      }
    },
  },

};
</script>

<style lang="css" scoped>
</style>
