<template>
  <div>
    <div class="content">
      <span class="subtitle has-text-weight-bold has-text-info">
        Organizations
      </span>
    </div>

    <div class="level is-mobile">
      <div class="level-left">
        <div class="level-item">
          <span class="has-text-grey has-text-weight-bold">
            View Organizations
          </span>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          <b-field>
            <b-switch
              type="is-info"
              v-model="view"
            />
          </b-field>
        </div>
      </div>
    </div>

    <div class="level is-mobile">
      <div class="level-left">
        <div class="level-item">
          <span class="has-text-grey has-text-weight-bold">
            Create Organizations
          </span>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          <b-field>
            <b-switch
              type="is-info"
              v-model="create"
            />
          </b-field>
        </div>
      </div>
    </div>

    <div class="level is-mobile">
      <div class="level-left">
        <div class="level-item">
          <span class="has-text-grey has-text-weight-bold">
            Edit Organizations
          </span>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          <b-field>
            <b-switch
              type="is-info"
              v-model="edit"
            />
          </b-field>
        </div>
      </div>
    </div>

    <div class="level is-mobile">
      <div class="level-left">
        <div class="level-item">
          <span class="has-text-grey has-text-weight-bold">
            Delete Organizations
          </span>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          <b-field>
            <b-switch
              type="is-info"
              v-model="remove"
            />
          </b-field>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

  name: 'PermissionsOrganizationsform',

  computed: {
    view: {
      get() {
        return this.$store.state.Permissions.permissions.admin.organizations.view;
      },

      set(value) {
        return this.$store.commit('Permissions/SET_ADMIN_ORGANIZATIONS_VIEW', value);
      },
    },

    create: {
      get() {
        return this.$store.state.Permissions.permissions.admin.organizations.create;
      },

      set(value) {
        return this.$store.commit('Permissions/SET_ADMIN_ORGANIZATIONS_CREATE', value);
      },
    },

    edit: {
      get() {
        return this.$store.state.Permissions.permissions.admin.organizations.edit;
      },

      set(value) {
        return this.$store.commit('Permissions/SET_ADMIN_ORGANIZATIONS_EDIT', value);
      },
    },

    remove: {
      get() {
        return this.$store.state.Permissions.permissions.admin.organizations.delete;
      },

      set(value) {
        return this.$store.commit('Permissions/SET_ADMIN_ORGANIZATIONS_DELETE', value);
      },
    },
  },

};
</script>

<style lang="css" scoped>
</style>
