<template>
  <div>
    <div class="content">
      <span class="heading has-divider is-size-5 has-text-weight-bold has-text-primary">
        Project Folders
      </span>
    </div>

    <div class="level is-mobile">
      <div class="level-left">
        <div class="level-item">
          <span class="has-text-grey has-text-weight-bold">
            View
          </span>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          <b-field>
            <b-switch
              type="is-info"
              v-model="view"
            />
          </b-field>
        </div>
      </div>
    </div>

    <div class="level is-mobile">
      <div class="level-left">
        <div class="level-item">
          <span class="has-text-grey has-text-weight-bold">
            Create
          </span>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          <b-field>
            <b-switch
              type="is-info"
              v-model="create"
            />
          </b-field>
        </div>
      </div>
    </div>

    <div class="level is-mobile">
      <div class="level-left">
        <div class="level-item">
          <span class="has-text-grey has-text-weight-bold">
            Edit
          </span>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          <b-field>
            <b-switch
              type="is-info"
              v-model="edit"
            />
          </b-field>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

  name: 'PermissionsProjectFoldersForm',

  computed: {
    view: {
      get() {
        return this.$store.state.Permissions.permissions.project_folders.view;
      },

      set(value) {
        return this.$store.commit('Permissions/SET_PROJECT_FOLDERS_VIEW_PERMISSIONS', value);
      },
    },

    create: {
      get() {
        return this.$store.state.Permissions.permissions.project_folders.create;
      },

      set(value) {
        return this.$store.commit('Permissions/SET_PROJECT_FOLDERS_CREATE_PERMISSIONS', value);
      },
    },

    edit: {
      get() {
        return this.$store.state.Permissions.permissions.project_folders.edit;
      },

      set(value) {
        return this.$store.commit('Permissions/SET_PROJECT_FOLDERS_EDIT_PERMISSIONS', value);
      },
    },
  },
};
</script>

<style lang="css" scoped>
</style>
